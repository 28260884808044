body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #282c34;
  color: white;
  line-height: 1.6;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  text-align: center;
}

section {
  padding: 2rem 1rem;
}

h1, h2 {
  font-weight: bold;
}

p {
  margin: 1rem 0;
}

.footer {
  background-color: #20232a;
  padding: 1rem 0;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}
