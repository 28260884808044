.countdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px);
    background: linear-gradient(135deg, #282c34 60%, #61dafb 100%);
    color: white;
    text-align: center;
    padding: 0 2rem;
}

.countdown h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
    font-family: 'Pacifico', cursive;
}

.countdown h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: 300;
}

.timer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: nowrap;
}

.time-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #20232a;
    padding: 1rem;
    border-radius: 10px;
    flex: 1 0 100px;
    margin: 0.5rem;
}

.time-unit span {
    font-size: 2.5rem;
    font-weight: bold;
}

.time-unit p {
    font-size: 1rem;
    margin-top: 0.5rem;
    color: #61dafb;
}

@media (max-width: 768px) {
    .countdown h1 {
        font-size: 2.5rem;
    }

    .countdown h2 {
        font-size: 1.2rem;
    }

    .time-unit span {
        font-size: 1.8rem;
    }

    .timer {
        flex-wrap: wrap;
    }
}
